import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// react components for routing our app without refresh
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
// @material-ui/icons
// core components

import Header from "components/Header/Header.jsx"
import Footer from "components/Footer/Footer.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"

import Parallax from "components/Parallax/Parallax.jsx"
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx"

import componentsStyle from "assets/jss/material-kit-react/views/components.jsx"
// import Team from "./Sections/Team.jsx";
import Introduction from "../About/Sections/Introduction.jsx"
import SEO from "../../components/SEO.jsx";
// import Instagram  from "./Instagram.jsx"
// import Download from "./Sections/Download.jsx"

class Welcome extends React.Component {
  render() {
    const { classes, ...rest } = this.props
    return (
      <div>
        <SEO/>
        <Header
          brand="Planwell"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white",
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/turbines.jpg")}>
          <div className={classes.container}>
            <GridContainer />
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <Introduction />
          <br />
        </div>
        <Footer />
      </div>
    )
  }
}

export default withStyles(componentsStyle)(Welcome)
