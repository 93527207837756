import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import typographyStyle from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.jsx"
import Typography from "@mui/material/Typography"

const Introduction = ({ classes }) => (
  <div id="intro" style={{ padding: "1rem", fontFamily: "Mandali" }}>
    <Typography
      variant="h3"
      sx={{
        mr: 2,
        display: "flex",
        fontWeight: 700,
        fontSize: { xs: "2rem", md: "4rem" },
        letterSpacing: ".3rem",
        color: "inherit",
        textDecoration: "none",
        flexGrow: 1,
        fontFamily: "Mandali",
      }}
    >
      Planning and energy development consultants
    </Typography>
    <Typography
      variant="h4"
      sx={{
        mr: 2,
        mt: 2,
        display: "flex",
        fontSize: { xs: "1.5rem", md: "3rem" },
        fontWeight: 500,
        color: "inherit",
        textDecoration: "none",
        flexGrow: 1,
        fontFamily: "Mandali",
      }}
    >
      by planning well we can delivery net zero with minimal local environmental
      effects and lowest cost - it makes sense
    </Typography>
    <Typography
      sx={{
        mr: 2,
        mt: 2,
        display: "flex",
        color: "inherit",
        textDecoration: "none",
        flexGrow: 1,
        fontFamily: "Mandali",
      }}
    >
      We are a planning and energy development consultancy with over 40 years
      experience covering both the public and private sector. We handle
      developments of all kinds from simple house extensions to major
      infrastructure, across the UK. In recent years we have evolved a
      specialism in energy related developments, such as solar parks, wind
      farms, overhead power lines, energy storage and intense energy users, such
      as data centres. We support both developers and communities affected by
      development proposals as well as planning authorities on policy and
      decisions.
    </Typography>
    <Typography
      sx={{
        mr: 2,
        mt: 2,
        display: "flex",
        flexDirection: "column",
        color: "inherit",
        textDecoration: "none",
        flexGrow: 1,
        fontFamily: "Mandali",
      }}
    >
      Our expertise includes:
      <br />
      <br />
      <ul>
        <li>assessing prospective projects at the pre-application stage; </li>
        <br />
        <li>
          preparing applications and taking them through the consenting process;
        </li>
        <br /> <li>appeals and post-decision reviews; </li>
        <br />
        <li>
          guiding affected communities on responding to development proposals;
        </li>
        <br />
        <li>
          preparing submissions in support of or objecting to major
          infrastructure;
        </li>
        <br />
        <li>
          Assessing cases in respect to contribution towards net zero, net
          economic benefit, sustainability and meeting site selection criteria;
        </li>
        <br />
        <li>acting as an expert witness in public inquiries and hearings; </li>
        <br />
        <li>
          guiding clients on the regulatory system and complexities of the
          energy system;
        </li>
        <br />
        <li>
          Assisting with the preparation of policies, including on energy and
          climate change;
        </li>
        <br /> <li>supporting clients engaged in public consultations; </li>
        <br /> <li>environmental law and compliance.</li>
      </ul>
    </Typography>
  </div>
)

export default withStyles(typographyStyle)(Introduction)
